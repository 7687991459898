import { useEffect, useState } from "react";
import HidePasswordEye from "./Icons/HidePasswordEye";
import ShowPasswordEye from "./Icons/ShowPasswordEye";

const Balance = ({ balance }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [visibleBalance, setVisibleBalance] = useState("****");

  const handleVisibility = () => {
    setIsVisible(!isVisible);
    setVisibleBalance(isVisible ? "****" : balance); // Toggle between the balance and ****
  };

  useEffect(() => {
    // When visibility changes, show the balance with $ if visible, else mask it
    setVisibleBalance(isVisible ? balance : "****");
  }, [isVisible, balance]);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex items-center justify-between mt-0 w-full max-w-xs px-0">
        {/* Balance display */}
        <div className="flex items-center gap-1 font-bold text-3xl text-slate-700">
          {/* Show $ sign only when the balance is visible */}
          {isVisible && <span>$</span>}
          <span>{visibleBalance}</span>
        

        {/* Eye icon to toggle visibility */}
        <div onClick={handleVisibility} className="cursor-pointer">
          {isVisible ? (
            <ShowPasswordEye className="w-5 h-5 text-slate-400" />
          ) : (
            <HidePasswordEye className="w-5 h-5 text-slate-400" />
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Balance;
