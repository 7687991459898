import { useEffect, useState } from "react";
import { ArrowLeft, InputField, MainButton } from "../components";
import { primaryColor, token } from "../constants/constants";
import { fetchUserInfo, updatePassword, updateUserInfo } from "../api/UserInfo";
import ShowPasswordEye from "../components/Icons/ShowPasswordEye";
import HidePasswordEye from "../components/Icons/HidePasswordEye";
import VirtualCardShimmer from "../components/VirtualCardShimmer";
import { useNavigate } from "react-router-dom";

const MyProfile = () => {
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameEror, setLastNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [obscurePassword, setObscurePassword] = useState(true);
  const [obscureConfirmPassword, setObscureConfirmPassword] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [oldPasswordObscure, setOldPasswordObscure] = useState(true);
  const [isLoading, setIsLoading] = useState("");
  const [toggle, setToggle] = useState(false);
  const [user, setUser] = useState();
  const [isVerified, setIsVerified] = useState(false);

  const navigate = useNavigate();
  const handlePasswordVisibility = () => {
    setObscurePassword(!obscurePassword);
  };
  const handleConfirmPasswordVisibility = () => {
    setObscureConfirmPassword(!obscureConfirmPassword);
  };
  const handleOldPasswordVisibility = () => {
    setOldPasswordObscure(!oldPasswordObscure);
  };

  const getUserI = async () => {
    try {
      const info = await fetchUserInfo();
      setUser(info.data);
      setFirstName(info.data.first_name);
      setLastName(info.data.last_name);
      setEmail(info.data.email);
      setPhone(info.data.phone);
      //   console.log(info.data);

      info.data.is_email_verified === "0"
        ? setIsVerified(false)
        : setIsVerified(true);
      return;
    } catch (e) {
      return;
    }
  };
  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      getUserI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const validateField = (field, errorSetter, errorMessage) => {
    if (!field) {
      errorSetter(errorMessage);
      return false;
    }

    return true;
  };
  const saveChangesClicked = () => {
    if (!toggle) {
      setIsLoading(true);
      setFirstNameError("");
      setLastNameError("");
      setEmailError("");
      setPhoneError("");

      if (
        !validateField(
          firstName,
          setFirstNameError,
          "The first name field is required"
        ) ||
        !validateField(
          lastName,
          setLastNameError,
          "The last name field is required"
        ) ||
        !validateField(phone, setPhoneError, "The phone field is required") ||
        !validateField(email, setEmailError, "The email field  is required")
      ) {
        return;
      }
      if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
        setEmailError("Please enter a valid email");
        return false;
      }
      var raw = JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
      });
      updateUserInfo(raw)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            navigate("/virtual-cards", { replace: true });
          } else {
            setIsLoading(false);
          }
          console.log(result);
        })
        .catch((error) => console.log("error", error));
    } else {
      setPasswordError("");
      setConfirmPasswordError("");
      setErrorMessage("");
      setOldPasswordError("");
      if (
        !validateField(
          password,
          setPasswordError,
          "The password field is required"
        ) ||
        !validateField(
          oldPassword,
          setOldPasswordError,
          "The old password field  is required"
        ) ||
        !validateField(
          confirmPassword,
          setConfirmPasswordError,
          "The confirm password field  is required"
        )
      ) {
        return;
      }
      if (password.length < 8) {
        setPasswordError("The password must be 8 characters or longer");
        return;
      }

      if (password !== confirmPassword) {
        setConfirmPasswordError("Passwords do not match");
        return;
      }
      setIsLoading(true);
      var rawD = JSON.stringify({
        oldPassword: oldPassword,
        newPassword: password,
      });
      updatePassword(rawD)
        .then((response) => response.json())
        .then((result) => {
          // return console.log(result);
        })
        .catch((error) => {
          return;
          // return console.log("error", error);
        });
    }
  };
  const changePasswordClicked = () => {
    setToggle(true);
  };
  return (
    <>
      <div className="w-full h-100 flex justify-center items-center bg-slate-10">
        {user !== undefined ? (
          <div className="w-full flex flex-col justify-center items-center ">
            {/* <div>My Profile</div> */}
            <div className="w-[90%] max-w-[600px] text-slate-700 p-2 sm:p-8 shadow-sm rounded-lg bg-white">
              {!toggle ? (
                <div>
                  <div
                    className={`text-[${primaryColor}] font-bold pb-3 text-lg text-center`}
                  >
                    Personal Information
                  </div>
                  <hr className="py-2" />
                  <div className="py-2">
                    <InputField
                      label={"First Name"}
                      value={firstName}
                      onchange={(e) => setFirstName(e.target.value)}
                    />
                    <label className="text-red-500 text-sm">
                      {firstNameError}
                    </label>
                  </div>
                  <div className="py-2">
                    <InputField
                      label={"Last Name"}
                      value={lastName}
                      onchange={(e) => setLastName(e.target.value)}
                    />
                    <label className="text-red-500 text-sm">
                      {lastNameEror}
                    </label>
                  </div>
                  <div className="py-2">
                    <InputField
                      label={"Email"}
                      value={email}
                      onchange={(e) => setEmail(e.target.value)}
                      type={"email"}
                      disabled={isVerified}
                    />
                    <label className="text-red-500 text-sm">{emailError}</label>
                  </div>
                  <div className="py-2">
                    <InputField
                      label={"Phone"}
                      value={phone}
                      onchange={(e) => setPhone(e.target.value)}
                    />
                    <label className="text-red-500 text-sm">{phoneError}</label>
                  </div>
                  <div className="py-2">
                    <div
                      className={`text-[${primaryColor}] cursor-pointer`}
                      onClick={changePasswordClicked}
                    >
                      Change Password
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex items-center pb-3">
                    <div onClick={() => setToggle(false)}>
                      <ArrowLeft className={"h-6 w-6 cursor-pointer"} />
                    </div>
                    <div
                      className={`text-[${primaryColor}] w-full flex justify-center font-bold  text-lg text-center`}
                    >
                      Change Password
                    </div>
                  </div>
                  <hr className="py-2" />
                  <div className="py-2">
                    <div className="flex items-start flex-col w-full gap-1">
                      <label>
                        Old Password <span className="text-red-500">*</span>
                      </label>
                      <div
                        className={`w-full flex border border-slate-600/50 rounded-md ${
                          oldPasswordError !== ""
                            ? "border-red-500"
                            : "border-slate-600/50"
                        }`}
                      >
                        <input
                          type={oldPasswordObscure ? "password" : "text"}
                          value={oldPassword}
                          placeholder="Old Password"
                          onChange={(ev) => setOldPassword(ev.target.value)}
                          className={`outline-none rounded-md px-3 py-2 w-full`}
                        />
                        <button
                          className="mr-2"
                          onClick={handleOldPasswordVisibility}
                        >
                          {oldPasswordObscure ? (
                            <ShowPasswordEye className={"h-6 w-6"} />
                          ) : (
                            <HidePasswordEye className={"h-6 w-6"} />
                          )}
                        </button>
                      </div>

                      <label className="text-red-500 text-sm">
                        {oldPasswordError}
                      </label>
                    </div>
                  </div>
                  <div className="py-2">
                    <div className="flex items-start flex-col w-full gap-1">
                      <label>
                        New Password <span className="text-red-500">*</span>
                      </label>
                      <div
                        className={`w-full flex border border-slate-600/50 rounded-md ${
                          passwordError !== ""
                            ? "border-red-500"
                            : "border-slate-600/50"
                        }`}
                      >
                        <input
                          type={obscurePassword ? "password" : "text"}
                          value={password}
                          placeholder="Confirm Password"
                          onChange={(ev) => setPassword(ev.target.value)}
                          className={`outline-none rounded-md px-3 py-2 w-full`}
                        />
                        <button
                          className="mr-2"
                          onClick={handlePasswordVisibility}
                        >
                          {obscurePassword ? (
                            <ShowPasswordEye className={"h-6 w-6"} />
                          ) : (
                            <HidePasswordEye className={"h-6 w-6"} />
                          )}
                        </button>
                      </div>

                      <label className="text-red-500 text-sm">
                        {passwordError}
                      </label>
                    </div>
                  </div>
                  <div className="py-2">
                    <div className="flex items-start flex-col w-full gap-1">
                      <label>
                        Confirm Password <span className="text-red-500">*</span>
                      </label>
                      <div
                        className={`w-full flex border border-slate-600/50 rounded-md ${
                          confirmPasswordError !== ""
                            ? "border-red-500"
                            : "border-slate-600/50"
                        }`}
                      >
                        <input
                          type={obscureConfirmPassword ? "password" : "text"}
                          value={confirmPassword}
                          placeholder="Confirm Password"
                          onChange={(ev) => setConfirmPassword(ev.target.value)}
                          className={`outline-none rounded-md px-3 py-2 w-full`}
                        />
                        <button
                          className="mr-2"
                          onClick={handleConfirmPasswordVisibility}
                        >
                          {obscureConfirmPassword ? (
                            <ShowPasswordEye className={"h-6 w-6"} />
                          ) : (
                            <HidePasswordEye className={"h-6 w-6"} />
                          )}
                        </button>
                      </div>

                      <label className="text-red-500 text-sm">
                        {confirmPasswordError}
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <br />
              {errorMessage && (
                <div className="text-center text-md text-red-500">
                  {errorMessage}
                </div>
              )}
              <br />
              <div className="py-2">
                <MainButton
                  label={"Save Chages"}
                  onClick={saveChangesClicked}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="w-full h-screen flex items-center justify-center">
              <VirtualCardShimmer width={500} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MyProfile;