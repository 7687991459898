export const calculatePrice = (val) => {
  let rate = 132;
  let discount = 0;
  let price = 0;
  const initialPrice = parseFloat(val) + 3;
  const basePercentage = 3.5;
  const percentage = (initialPrice * basePercentage) / 100;
  const percentagePrice = initialPrice + percentage;
  
  if (val >= 301 && val < 1001) {
    const rangePercentage = 13; // Desired percentage for this range
    const totalPrice = parseInt((percentagePrice * (1 + rangePercentage / 100)) * rate) + 500;
    price = totalPrice + 2010;
    discount = totalPrice - 1320;
  }
  // if (val >= 201 && val <= 300) {
  //   const rangePercentage = 10; // Desired percentage for this range
  //   const totalPrice = parseInt((percentagePrice * (1 + rangePercentage / 100)) * rate) + 500;
  //   price = totalPrice;
  //   discount = totalPrice - 1300;
  // }
  if (val >= 101 && val <= 300) {
    const rangePercentage = 13; // Desired percentage for this range
    const totalPrice = parseInt((percentagePrice * (1 + rangePercentage / 100)) * rate) + 500;
    price = totalPrice;
    discount = totalPrice - 1296;
  }
  if (val > 51 && val <= 100) {
    const rangePercentage = 14; // Desired percentage for this range
    const totalPrice = parseInt((percentagePrice * (1 + rangePercentage / 100)) * rate) + 500;
    price = totalPrice;
    discount = totalPrice - 402;
  }
  if (val > 21 && val <= 51) {
    const rangePercentage = 14; // Desired percentage for this range
    const totalPrice = parseInt((percentagePrice * (1 + rangePercentage / 100)) * rate) + 500;
    price = totalPrice;
    discount = totalPrice - 513;
  }
  if (val <= 21) {
    const rangePercentage = 10; // Desired percentage for this range
    const totalPrice = parseInt((percentagePrice * (1 + rangePercentage / 100)) * rate) + 100;
    price = totalPrice;
    discount = totalPrice + 593;
  }

  return {
    price: price.toLocaleString(),
    discount: discount,
  };
};

export const calculateUsdtPrice = (val) => {
  const creationFee = 4;
  const rechargeFee = 9.5;
  let initialPrice = parseFloat(val);
  let percentageValue = (initialPrice * rechargeFee) / 100;
  const price = initialPrice + percentageValue + creationFee;

  return {
    price: price.toFixed(2),
  };
};
