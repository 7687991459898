import { useState, useEffect, useRef } from "react";
// import { Menu } from "../components";
import { NavLink, useNavigate } from "react-router-dom";
import { logo } from "../assets";
import { primaryColor } from "../constants/constants";
import { fetchUserInfo } from "../api/UserInfo";

const Navbar = ({ MyAccountClicked, token }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [user, setUser] = useState(null);
  const [isListVisible, setIsListVisible] = useState(false); // State for list visibility
  const dropdownRef = useRef(null);

  const navigate = useNavigate();

  const handleMenuToggle = () => {
    MyAccountClicked(false);
    if (window.innerWidth <= 768) {
      setIsExpanded(!isExpanded);
    }
  };

  const toggleDropdown = () => {
    setIsListVisible(!isListVisible); // Toggle list visibility
  };

  const getUserI = async (token) => {
    try {
      const info = await fetchUserInfo(token);
      setUser(info.data);
      return;
    } catch (e) {
      return;
    }
  };

  const handleProfileClick = () => {
    navigate("/my-Profile");
    setIsListVisible(false); // Hide dropdown after navigating
  };

  const handleLogoutClick = () => {
    // Clear user token and other logout logic here
    localStorage.removeItem("token");
    navigate("/login");
    setIsListVisible(false); // Hide dropdown after logging out
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsListVisible(false);
    }
  };

  useEffect(() => {
    getUserI(token);
    if (window.innerWidth >= 768) {
      setIsExpanded(true);
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [token]);

  return (
    <>
      <div className="relative w-full">
        <nav className="sticky top-0 w-full flex items-center justify-between mb-2 p-2 bg-transparent z-50">
          <div className="flex items-center hover:cursor-pointer mt-6">
           
          </div>
          <ul className={`md:items-center gap-4 flex flex-col md:flex-row ${isExpanded ? "h-auto py-10" : "h-0"} overflow-hidden transition-all duration-100`}>
            
            {token !== null ? (
              <>
                <div></div>
              </>
            ) : (
              <>
                <NavLink onClick={handleMenuToggle} to="/sign-up" className={({ isActive, isPending }) => (isPending ? "pending" : isActive ? "text-[#2cb4d5]" : "")}>
                  Sign up
                </NavLink>
                <NavLink
                  onClick={handleMenuToggle}
                  to="login"
                  className={({ isActive, isPending }) =>
                    isPending
                      ? "pending text-slate-300"
                      : isActive
                      ? "bg-[#2cb4d5] text-white px-5 py-1 rounded-xl shadow-none text-center"
                      : "text-white border bg-[#1f7e95] px-5 py-1 rounded-xl shadow-none text-center"
                  }
                >
                  Login
                </NavLink>
              </>
            )}
          </ul>
          <div onClick={() => navigate("/virtual-cards")} className="absolute top-0 left-0">
          <img src={logo} alt="logo" className="h-14 w-14 mb-2 p-2" />
        </div>
          <div className="relative flex items-center ml-auto" ref={dropdownRef}>
            {token !== null ? (
              <div className="relative">
                <div
                  onClick={toggleDropdown}
                  className={`h-10 w-10 bg-[${primaryColor}]/30 rounded-full border flex justify-center items-center text-md text-slate-600 border-[${primaryColor}] cursor-pointer`}
                >
                  {user && user.first_name.charAt(0)}
                </div>
                {isListVisible && (
                  <ul className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg">
                    <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer" onClick={handleProfileClick}>Profile</li>
                    <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer" onClick={handleLogoutClick}>Logout</li>
                  </ul>
                )}
              </div>
              
            ) : (
              <div onClick={handleMenuToggle} className="w-8 h-8 md:hidden">
                {/* <Menu style={{ height: "1.5rem", width: "1.5rem" }} /> */}
              </div>
            )}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;