import { useContext, useEffect, useState } from "react";
import {
  // AddIcon,
  ArrowDown,
  Balance,
  CardDetail,
  CardDetailIcon,
  CardInfo,
  DeleteModal,
  // EditIcon,
  ExclamationCircleIcon,
  MainButton,
  Modal,
  NoRecordFound,
  VerificationMessage,
  VirtualCardTitle,
} from "../components";
import { baseUrl, primaryColor, secondaryColor } from "../constants/constants";
import { LoginContext } from "../Contexts/loginContext";
import {
  CardVisaImage,
  CheckImage,
  // VisaLogo,
  cardImg1,
  creditCardSvg,
  kycCheckPrivacy,
  // masterCardLogo,
  verifyEmail,
} from "../assets";
import { fetchUserInfo } from "./../api/UserInfo";
import { useNavigate } from "react-router-dom";
import { fetchCardDetails } from "../api/fetchApi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import VirtualCardShimmer from "../components/VirtualCardShimmer";
import { VirtualCardLanding } from "./VirtualCardLanding";
import { getTransaction } from "../api/transactions";
import CircularArrowUpIcon from "../components/Icons/CircularArrowUpIcon";
import TransactionCard from "../components/TransactionCard";
import CircularArrowDownIcon from "../components/Icons/CircularArrowDownIcon";
import PaymentModal from "../components/PaymentModal";
import { useBackButton } from "../Contexts/BackButtonContext";

const VirtualCards = () => {
  const { user, setUser } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [cards, setCards] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [fundingModal, setFundingModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [formattedNumber, setFormattedNumber] = useState("");
  const [tabToggle, setTabToggle] = useState("transaction");
  const [transactions, setTransactions] = useState([]);
  const [deposits, setDeposits] = useState();
  const [transactionIsLoading, setTransactionIsLoading] = useState(true);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [counter, setCounter] = useState(30);
  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  const handleToggle = (value) => {
    setTabToggle(value);
  };
  useEffect(() => {
    const timer =
      counter > 0 &&
      isEmailSent &&
      setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setIsEmailSent(false);
      setCounter(30);
    }
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, isEmailSent]);
  const handleItemClick = (index) => {
    setActiveIndex(index);
    if (cards.length > 0) {
      fromatCardNumber(cards[activeIndex].card_number);
    }
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const { label, setLabel } = useBackButton();

  const getUserI = async (token) => {
    try {
      const info = await fetchUserInfo(token);
      setUser(info.data);
      info.data.is_kyc_completed === 0
        ? setIsVerified(false)
        : setIsVerified(true);
      return;
    } catch (e) {
      return;
    }
  };

  useEffect(() => {
    if (token) {
      getCardDetail(token);
      getUserI(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    cards.length > 0 && fromatCardNumber(cards[activeIndex].card_number);
    cards.length > 0 && getCardTransaction(cards[activeIndex].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  const fromatCardNumber = (number) => {
    const numberString = number.toString();
    // const firstFourDigits = numberString.slice(0, 4); // Get the first four digits
    const lastFourDigits = numberString.slice(-4); // Get the last four digits
    const maskedPart = '****'; // Mask the middle digits with '****'
  
    // Combine the first four digits, masked part, and last four digits
    // const maskedNumber = firstFourDigits + ' ' + maskedPart + ' ' + maskedPart + ' ' + lastFourDigits;
    const maskedNumber = maskedPart + ' ' + lastFourDigits;
    setFormattedNumber(maskedNumber); // Set the formatted card number
  };
  

  const getCardDetail = (token) => {
    fetchCardDetails(token)
      .then((response) => response.json())
      .then((result) => result.data.cards)
      .then((result) => {
        setCards(result);
        fromatCardNumber(result[0].card_number);
        getCardTransaction(result[0].id);
        // console.log(result);
      })
      .catch((error) => {
        return;
      });
  };

  const getCardTransaction = (id) => {
    setTransactionIsLoading(true);
    getTransaction(id)
      .then((response) => response.json())
      .then((result) => {
        setTransactions(result.transactions);
        setDeposits(result.recharge);
      })
      .then(() => {
        setTransactionIsLoading(false);
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  function createCard() {
    setCreateModal(true);
    setLabel("createcard");
    isVerified ? openModal() : closeModal();
  }

  function resendVerificationCode() {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseUrl}email/verification-notification`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setIsEmailSent(true);
        setIsLoading(false);
        return result;
      })
      .catch((error) => {
        return;
      });
  }

  const verifyUser = () => {
    navigate("verify");
    setIsLoading(true);
  };

  const handleModal = (val) => {
    setCreateModal(false);
    setDeleteModal(false);
    setFundingModal(false);
    if (val === "create") {
      setLabel("createcard");
      return setCreateModal(true);
    } else if (val === "fund") {
      setLabel("fund");
      return setFundingModal(true);
    } else if (val === "delete") {
      return setDeleteModal(true);
    }
  };

  const [isPC, setIsPC] = useState(false); // State to track if the device is a PC

  // Check if the device is a PC (width greater than 1024px)
  useEffect(() => {
    const checkDeviceType = () => {
      if (window.innerWidth > 600) {
        setIsPC(true); // It's a PC
      } else {
        setIsPC(false); // It's not a PC (mobile/tablet)
      }
    };

    checkDeviceType(); // Initial check
    window.addEventListener("resize", checkDeviceType); // Add resize event listener to check on resize

    return () => {
      window.removeEventListener("resize", checkDeviceType); // Cleanup on unmount
    };
  }, []);

  if (isPC) {
    // If it's a PC, only show the caution message and nothing else
    return (
      <div className="w-full h-screen flex items-center justify-center bg-white">
        <div className="bg-yellow-100 text-black text-center py-4 px-6 text-xl sm:text-2xl font-medium">
          ⚠️ PC version is currently under maintenance. Please use a mobile device.
        </div>
      </div>
    );
  }

  return (
    <>
      {token ? (
        <div className={`min-h-screen w-full flex justify-center `}>
          <div className="w-[90%] xl:w-[65%] flex flex-col items-center ">
            {user.is_email_verified === 0 ? (
              <div className=" w-full h-full min-h-36 flex flex-col">
                <div className="flex items-end">
                  <div className="text-xl font-bold pr-2">Hello,</div>
                  <VirtualCardTitle
                    title={user.first_name + " " + user.last_name}
                    className={
                      "text-2xl lg:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#2cb4d5] to-[#1a6c80]"
                    }
                  />
                </div>
                {isEmailSent ? (
                  <>
                    <div className="w-full h-full flex items-center justify-center">
                      <div className="w-[90%] md:w-[50%] bg-white rounded-md border border-slate-100 min-h-[300px] flex flex-col items-center py-6 shadow">
                        <div className="text-center font-bold text-slate-700 text-xl py-8">
                          Verification Email Sent Successfully
                        </div>
                        <div className="h-48 w-48 flex justify-center ">
                          <img src={CheckImage} alt="" />
                        </div>
                        <div className="p-6 text-center text-slate-700 bg-yellow-50 rounded-e-md m-5">
                          If our email is not in your inbox, please check your
                          spam or junk folder and mark it as 'Not Spam' to
                          verify your email and ensure future deliveries.
                        </div>
                        <div>
                          Resend verification email in {counter} seconds
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <VerificationMessage
                    img={verifyEmail}
                    title={"Verify Your email addres"}
                    onClick={resendVerificationCode}
                    isLoading={isLoading}
                    body={` By verifying your account, you ensure a secure and
                  seamless experience while using our services. If you
                  encounter any issues or have questions, feel free to
                  contact our support team at support@yehacard.com
                    `}
                  />
                )}
              </div>
            ) : (
              <div className=" w-full min-h-36 flex flex-col  ">
                <div>
                  {user.status === 2 ? (
                    <>
                      <div>
                        <div className="w-full p-2 bg-red-400/30 rounded-md flex flex-col sm:flex-row justify-between items-center my-2">
                          <div className="p-2 text-slate-700">
                            Verification failed. Please review guidelines and
                            resubmit for secure virtual card access.
                          </div>
                          <div
                            onClick={() => verifyUser()}
                            className={`px-5 py-1 bg-[${secondaryColor}] w-full text-center sm:w-auto text-white rounded-sm cursor-pointer`}
                          >
                            Verify
                          </div>
                        </div>
                      </div>
                    </>
                  ) : user.is_kyc_completed === 0 ? (
                    <>
                      <div className="w-full p-2 bg-amber-400/30 rounded-md flex flex-col sm:flex-row justify-between items-center my-2">
                        <div className="p-2 text-slate-700">
                          To use virtual cards, we just need to confirm your
                          identity. It's a quick step to make sure everything
                          stays secure for you.
                        </div>
                        <div
                          onClick={() => verifyUser()}
                          className={`px-5 py-1 bg-[${secondaryColor}] w-full text-center sm:w-auto text-white rounded-sm cursor-pointer`}
                        >
                          Verify
                        </div>
                      </div>
                      <div></div>
                    </>
                  ) : user.pending_order > 0 ? (
                    <>
                      <div className="w-full p-2 bg-blue-200/30 rounded-md flex justify-between items-center my-2">
                        <div className="p-2">
                        New card order is under review.
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {user.is_kyc_completed === 1 &&
                  user.is_kyc_approved === 1 &&
                  cards.length > 0 &&
                  cards[activeIndex].pending_fund_order !== 0 ? (
                    <>
                      <div className="hidden md:block w-full p-4 bg-green-400/30 rounded-md  items-center my-2">
                      Funding order under review. Available once approved.
                      </div>
                    </>
                  ) : cards.length > 0 &&
                    parseInt(cards[activeIndex].balance) <= 2 &&
                    cards[activeIndex].is_active < 3 ? (
                    <>
                      <div className="hidden md:block w-full p-4 bg-red-400/30 rounded-md  items-center my-2">
                        Your balance is running low. Please recharge before your
                        card is frozen.
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {/* {console.log(user.is_kyc_completed)} */}
                {user.is_kyc_completed === 1 && user.is_kyc_approved === 1 ? (
                  <>
                   <div className="flex justify-between items-center w-full">
  {/* Mobile view Balance */}
  <div className="md:hidden block pt-1 px-3">
    {cards.length > 0 ? (
      activeIndex === undefined ? (
        <Balance balance={cards[0].balance} />
      ) : (
        <Balance balance={cards[activeIndex].balance} />
      )
    ) : (
      <></>
    )}
  </div>
  {/* Mobile view Balance Ends */}

  {user.pending_order === 0 && user.max_card_limit > user.number_of_active_card && (
    <div
      onClick={createCard}
      className={`flex border border-[${primaryColor}] rounded-md text-md lg:text-xl items-center px-2 py-2 gap-2 text-slate-800 hover:bg-[#2cb4d5]/30 cursor-pointer mb-3`}
    >
      {/* <AddIcon className="w-5 h-5 font-bold" /> */}
      + Card
    </div>
  )}
</div>


                    
                    <Modal isOpen={isModalOpen} onClose={closeModal}>
                      <div className="max-h-[720px] flex items-center p-2 font-grotesk">
                        {createModal ? (
                          <PaymentModal label={label} />
                        ) : fundingModal ? (
                          <PaymentModal
                            label={label}
                            card={
                              activeIndex !== "" ? cards[activeIndex] : cards[0]
                            }
                          />
                        ) : deleteModal ? (
                          <div className="min-w-[270px] max-w-[350px]">
                            <div className="border-b border-b-slate-300 w-full flex gap-2 pb-2">
                              <ExclamationCircleIcon className="w-6 h-6 text-red-500" />
                              <div className="font-bold">Pay attention</div>
                            </div>
                            <DeleteModal
                              isChecked={isChecked}
                              isLoading={isLoading}
                              handleCheckboxChange={handleCheckboxChange}
                              onClick={() => {
                                if (!isChecked) {
                                  setIsLoading(false);
                                  return;
                                }
                                setIsLoading(true);
                              }}
                            />
                          </div>
                        ) : (
                          <>
                           <div className="flex flex-col justify-evenly w-full">
                              <div className="text-slate-600 font-bold text-2xl flex gap-1 w-full justify-bottom items-center">
                                {/* <div>
                                  {activeIndex !== "" ? (
                                    <img
                                      src={
                                        cards.length > 0 &&
                                        cards[activeIndex].card_type === "visa"
                                          ? VisaLogo
                                          : masterCardLogo
                                      }
                                      alt="logo"
                                      className="w-20 h-auto "
                                    />
                                  ) : (
                                    <img
                                      src={
                                        cards.length > 0 &&
                                        cards[0].card_type === "visa"
                                          ? VisaLogo
                                          : masterCardLogo
                                      }
                                      alt="logo"
                                      className="w-12 h-auto"
                                    />
                                  )}
                                </div> */}
                                
                                <div className="text-center">Your Card Details</div>

                              </div>
                              {/* <CardInfo /> */}
                              {cards.length > 0 && (
                                <CardDetail
                                  card_number={
                                    activeIndex !== ""
                                    
                                      ? cards[activeIndex].card_number
                                      : cards[0].card_number
                                  }
                                  cvv={
                                    activeIndex !== ""
                                      ? cards[activeIndex].cvv
                                      : cards[0].cvv
                                  }
                                  holder_name={
                                    activeIndex !== ""
                                      ? cards[activeIndex].holder_name
                                      : cards[0].holder_name
                                  }
                                  expired_date={
                                    activeIndex !== ""
                                      ? cards[activeIndex].expired_date
                                      : cards[0].expired_date
                                  }
                                  address={
                                    activeIndex !== ""
                                      ? cards[activeIndex].address
                                      : cards[0].address
                                  }
                                  country={
                                    activeIndex !== ""
                                      ? cards[activeIndex].country
                                      : cards[0].country
                                  }
                                  city={
                                    activeIndex !== ""
                                      ? cards[activeIndex].city
                                      : cards[0].city
                                  }
                                  state={
                                    activeIndex !== ""
                                      ? cards[activeIndex].state
                                      : cards[0].state
                                  }
                                  street={
                                    activeIndex !== ""
                                      ? cards[activeIndex].street
                                      : cards[0].street
                                  }
                                  zipcode={
                                    activeIndex !== ""
                                      ? cards[activeIndex].zip_code
                                      : cards[0].zip_code
                                  }
                                />
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </Modal>
                    {user.has_active_card === 1 ? (
                      <>
                        <div className="w-full flex justify-center items-center">
                          <div className="flex flex-col md:flex-row gap-5 w-full justify-center lg:w-full  mt-0 ">
                            <div className=" ">
                              <div className="w-full flex justify-start">
                                {/* <CardInfo /> */}
                              </div>
                              <div className="flex min-w-[280px] justify-center ">
                                {cards.length > 0 ? (
                                  <Swiper
                                    onSlideChange={(e) =>
                                      handleItemClick(e.activeIndex)
                                    }
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    pagination={{
                                      clickable: true,
                                    }}
                                    breakpoints={{
                                      640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                      },
                                      768: {
                                        slidesPerView: 1,
                                        spaceBetween: 40,
                                      },
                                      1024: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                      },
                                      1249: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                      },
                                    }}
                                    modules={[Pagination]}
                                    className="  max-w-[400px]  "
                                  >
                                    {Array.isArray(cards) &&
                                      cards.length > 0 &&
                                      cards.map((card0, index) => {
                                        return (
                                          <SwiperSlide
                                            key={index}
                                            className={`w-full h-full `}
                                          >
                                            <div
                                              className={`h-full p-2 md:cursor-pointer transition-transform duration-300 transform ${
                                                cards.length === 1
                                                  ? "lg:w-[400px]"
                                                  : "w-auto "
                                              }  ${
                                                index === activeIndex
                                                  ? "scale-105 opacity-100"
                                                  : "opacity-90"
                                              }`}
                                              onClick={() => {
                                                return handleItemClick(index);
                                              }}
                                            >
                                              <div className="relative w-full min-w-[270px] max-w-[400px]">
                                                <img
                                                  className="object-cover "
                                                  style={{
                                                    opacity:
                                                      card0.is_active === "1"
                                                        ? 1
                                                        : 0.5,
                                                  }}
                                                  src={
                                                    card0.card_type === "visa"
                                                      ? CardVisaImage
                                                      : cardImg1
                                                  }
                                                  alt={`Carousel Item ${
                                                    index + 1
                                                  }`}
                                                />
                                                <div className="absolute bottom-11 left-6 text-white text-md font-bold font-grotesk">
                                                  {card0.holder_name}
                                                </div>
                                                <div className="absolute bottom-3 left-6 text-white text-md font-semibol font-card-number ">
                                                  {formattedNumber}
                                                </div>
                                              </div>
                                            </div>
                                          </SwiperSlide>
                                        );
                                      })}
                                  </Swiper>
                                ) : (
                                  <>
                                    <VirtualCardShimmer
                                      rounded={"rounded-lg"}
                                      className={
                                        "w-[400px] bg-slate-200 h-[250px] rounded-lg"
                                      }
                                    />
                                  </>
                                )}
                              </div>

                              {cards.length > 0 &&
                                cards[activeIndex].is_active === "2" && (
                                  <div className="w-full px-2 py-3">
                                    <div className="flex w-full px-6 py-2 items-start gap-2 bg-red-100 rounded-xl">
                                      <ExclamationCircleIcon className="w-10 h-10 text-red-500" />
                                      <div>
                                        <div className="text-md py-2 font-bold">
                                          Card Frozen
                                        </div>
                                        <div className="text-sm">
                                          Your card has been overdrawn or Suspended due to suspecious activity.
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      onClick={() => {
                                        handleModal("fund");
                                        openModal(); // Assuming openModal is defined elsewhere
                                      }}
                                      style={{
                                        backgroundColor: secondaryColor,
                                      }}
                                      className={`text-white w-full text-center p-2 rounded-xl mt-3 font-bold sm:cursor-pointer`}
                                    >
                                      Unfrozen Submit
                                    </div>
                                  </div>
                                )}
                              {user.is_kyc_completed === 1 &&
                              user.is_kyc_approved === 1 &&
                              cards.length > 0 &&
                              cards[activeIndex].pending_fund_order !== 0 ? (
                                <>
                                  <div className="block md:hidden w-full p-4 bg-green-400/30 rounded-md  items-center my-2">
                                     Funding order under review. Available once approved.
                                  </div>
                                </>
                              ) : cards.length > 0 &&
                                parseInt(cards[activeIndex].balance) <= 2 &&
                                cards[activeIndex].is_active < 3 ? (
                                <>
                                  <div className="block md:hidden w-full p-4 bg-gray-400/30 rounded-md  items-center my-2">
                                  ⚠️ Your balance is running low. 
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {cards.length > 0 &&
                                cards[activeIndex].is_active === "3" && (
                                  <div className="w-full px-2 py-3">
                                    <div className="flex w-full px-6 py-2 items-start gap-2 bg-red-100 rounded-xl">
                                      <ExclamationCircleIcon className="w-10 h-10 text-red-500" />
                                      {cards[activeIndex].balance >= 10 ? (
                                        <div>
                                          <div className="text-md py-2 font-bold">
                                            The card has been canceled
                                          </div>
                                          <div className="text-sm">
                                            The card has stopped its services.
                                            To safeguard your rights, we have
                                            removed your card.
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          <div className="text-md py-2 font-bold">
                                            Card is removed
                                          </div>
                                          <div className="text-sm">
                                            You have removed this card. Please
                                            refrain from using it for any
                                            further purchases.
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {cards[activeIndex].balance >= 10 && (
                                      <div
                                        onClick={() => {
                                          handleModal("delete");
                                          openModal();
                                        }}
                                        style={{
                                          backgroundColor: secondaryColor,
                                        }}
                                        className={`text-white w-full text-center p-2 rounded-xl mt-3 font-bold sm:cursor-pointer`}
                                      >
                                        Delete Card
                                      </div>
                                    )}
                                  </div>
                                )}
                              {cards.length > 0 &&
                                cards[activeIndex].is_active !== "3" && (
                                  <div className="w-full flex justify-around mt-2">
                                    <div className="h-20 w-20 flex flex-col items-center">
                                      <div
                                        // className="h-18 w-18 bg-slate-300 rounded-full p-3 flex justify-center sm:cursor-pointer"
                                        onClick={() => {
                                          handleModal("fund");

                                          return openModal();
                                        }}
                                      >
                                        {/* <ArrowDown className={"w-6 h-6"} /> */}
                                      </div>
                                      <div
  
                                        className={`h-18 w-18 rounded-full p-3 flex justify-center ${
                                          cards[activeIndex]?.pending_fund_order !== 0
                                            ? "bg-gray-300 cursor-not-allowed"
                                            : "bg-slate-300 sm:cursor-pointer"
                                        }`}
                                        onClick={() => {
                                          if (cards[activeIndex]?.pending_fund_order === 0) {
                                            handleModal("fund");
                                            openModal();
                                          }
                                        }}
                                        style={{
                                          pointerEvents: cards[activeIndex]?.pending_fund_order !== 0 ? "none" : "auto", // Disable pointer interaction
                                        }}
                                      >
                                        <ArrowDown className="w-6 h-6" />
                                      </div>
                                      {/* Recharge Label */}
                                      <div
                                        className={`text-sm py-2 ${
                                          cards[activeIndex]?.pending_fund_order !== 0
                                            ? "text-gray-400"
                                            : "text-slate-700"
                                        }`}
                                      >
                                        {cards[activeIndex]?.pending_fund_order !== 0
                                          ? "Unavailable"
                                          : "Recharge"}
                                      </div>

                                    </div>
                                    {cards.length > 0 && cards[activeIndex].is_active === "1" ? (
                                  <div
                                    className="h-20 w-20 flex flex-col items-center sm:cursor-pointer"
                                    onClick={() => {
                                      // Swap the functionality: now "Freeze" triggers the modal
                                      // setCreateModal(false);
                                      // setFundingModal(false);
                                      // handleModal("delete"); // You can change this as per your need
                                      openModal(); // Open the modal
                                    }}
                                  >
                                    <div className="h-0 w-0 bg-slate-0 rounded-full p-3 flex justify-center">
                                      {/* <EditIcon className={"w-6 h-6"} /> */}
                                    </div>
                                    {/* <div className="text-sm text-slate-700 py-1">
                                      Freeze
                                    </div> */}
                                  </div>
                                ) : (
                                  <div className="h-20 w-20 flex flex-col items-center">
                                    <div className="justify-center">
                                      {/* <EditIcon className={"w-6 h-6 text-gray-500"} /> */}
                                    </div>
                                    <div className="text-sm text-slate-400 py-1">
                                      
                                    </div>
                                  </div>
                                )}

                                {cards.length > 0 && cards[activeIndex].is_active === "1" ? (
                                  <div
                                    className="h-20 w-20 flex flex-col items-center"
                                    onClick={() => {
                                      // Swap the functionality: now "Details" triggers the modal
                                      handleModal(""); // Trigger the modal for "Details"
                                      openModal(); // Open the modal
                                    }}
                                  >
                                    <div className="h-18 w-18 bg-slate-300 rounded-full p-3 flex justify-center sm:cursor-pointer">
                                      <CardDetailIcon className={"w-6 h-6"} />
                                    </div>
                                    <div className="text-sm text-slate-700 py-1">
                                      Details
                                    </div>
                                  </div>
                                ) : (
                                  <div className="h-20 w-20 flex flex-col items-center">
                                    <div className="h-18 w-18 bg-slate-200 rounded-full p-3 flex justify-center ">
                                      <CardDetailIcon className={"w-6 h-6 text-gray-500"} />
                                    </div>
                                    <div className="text-sm text-slate-400 py-1">
                                      Details
                                    </div>
                                  </div>
                                )}
                                </div>
                                )}
                            </div>

                            <div className="w-full space-y-3 ">
                              <div className="md:block hidden">
                                {cards.length > 0 ? (
                                  // activeIndex === undefined ? (
                                  //   <Balance balance={cards[0].balance} />
                                  // ) : (
                                  <Balance
                                    balance={
                                      activeIndex === undefined
                                        ? cards[0].balance
                                        : cards[activeIndex].balance
                                    }
                                  />
                                ) : (
                                  // )
                                  <>
                                    <div className="w-full flex flex-col gap-5">
                                      <div className="flex  flex-col gap-3">
                                        <VirtualCardShimmer
                                          rounded={"rounded-lg"}
                                          className={
                                            "w-[150px] bg-slate-200 h-[30px] rounded-lg"
                                          }
                                        />
                                        <VirtualCardShimmer
                                          rounded={"rounded-lg"}
                                          className={
                                            "w-[160px] bg-slate-200 h-[50px] rounded-lg"
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="w-full  h-full space-y-2">
                              <div
                                  className="relative flex max-w-[400px] w-full h-11 bg-gray-200 rounded-full items-center p-1"
                                >
                                  {/* Slider */}
                                  <div
                                    className={`absolute top-1 left-[6px] h-[80%] w-[48.3%] bg-white rounded-full transform transition-all duration-300 ${
                                      tabToggle === "recharge" ? "translate-x-[100%]" : "translate-x-0"
                                    }`}
                                  ></div>

                                  {/* Transaction Button */}
                                  <div
                                    onClick={() => handleToggle("transaction")}
                                    className={`flex-1 text-center z-10 text-sm font-medium ${
                                      tabToggle === "transaction" ? "text-black" : "text-gray-500"
                                    } cursor-pointer`}
                                  >
                                    Transaction
                                  </div>

                                  {/* Recharge Button */}
                                  <div
                                    onClick={() => handleToggle("recharge")}
                                    className={`flex-1 text-center z-10 text-sm font-medium ${
                                      tabToggle === "recharge" ? "text-black" : "text-gray-500"
                                    } cursor-pointer`}
                                  >
                                    Recharge
                                  </div>
                                </div>


                                <div className="bg-white w-full h-[420px] p-2 rounded-md space-y-2 overflow-y-auto">
                                  {!transactionIsLoading ? (
                                    tabToggle === "transaction" ? (
                                      transactions.length > 0 ? (
                                        transactions.map((e, index) => (
                                          <TransactionCard
                                            failurity={e.reason}
                                            key={index}
                                            status={e.status}
                                            amount={e.amount}
                                            date={e.date}
                                            reason={e.paid_for}
                                            sign={"-"}
                                            icon={
                                              <CircularArrowUpIcon
                                                className={`w-7 h-7 ${
                                                  e.status === "Failed"
                                                    ? "text-red-500"
                                                    : "text-slate-600"
                                                }`}
                                              />
                                            }
                                          />
                                        ))
                                      ) : (
                                        <>
                                          <>
                                            <NoRecordFound
                                              title={
                                                "No transaction record found."
                                              }
                                              body={
                                                "Oops! You haven't made any transaction with your card yet."
                                              }
                                            />
                                          </>
                                        </>
                                      )
                                    ) : deposits.length > 0 ? (
                                      deposits.map((e, index) => (
                                        <TransactionCard
                                          failurity={null}
                                          key={index}
                                          status={e.status}
                                          amount={e.amount}
                                          date={e.date}
                                          sign={"+"}
                                          reason={"Recharge"}
                                          icon={
                                            <CircularArrowDownIcon
                                              className={`w-7 h-7 ${
                                                e.status === "Failed"
                                                  ? "text-red-500"
                                                  : ""
                                              }`}
                                            />
                                          }
                                        />
                                      ))
                                    ) : (
                                      <>
                                        <NoRecordFound
                                          title={"No recharge record found."}
                                          // body={
                                          //   "Oops! You haven't recharged your card yet."
                                          // }
                                        />
                                      </>
                                    )
                                  ) : (
                                    <>
                                      <VirtualCardShimmer
                                        rounded={"rounded-lg"}
                                        className={
                                          "w-full bg-slate-200 h-[75px] rounded-lg"
                                        }
                                      />
                                      <VirtualCardShimmer
                                        rounded={"rounded-lg"}
                                        className={
                                          "w-full bg-slate-200 h-[75px] rounded-lg"
                                        }
                                      />
                                      <VirtualCardShimmer
                                        rounded={"rounded-lg"}
                                        className={
                                          "w-full bg-slate-200 h-[75px] rounded-lg"
                                        }
                                      />
                                      <VirtualCardShimmer
                                        rounded={"rounded-lg"}
                                        className={
                                          "w-full bg-slate-200 h-[75px] rounded-lg"
                                        }
                                      />
                                      <VirtualCardShimmer
                                        rounded={"rounded-lg"}
                                        className={
                                          "w-full bg-slate-200 h-[75px] rounded-lg"
                                        }
                                      />
                                    </>
                                  )}
                                </div>
                                <div className="w-full bg-white border-t border-slate-300 rounded-b-lg text-sm text-slate-600">
                                  <div className="px-3 py-2">Notice</div>
                                  {/* <div className="w-full flex flex-col sm:flex-row md:flex-col lg:flex-row justify-between border-t border-slate-300 px-3 py-2">
                                    <div className="text-semibold">
                                      Small purchase fee:
                                    </div>
                                    <div>
                                      0.15USD (Under 10 USD of consumption)
                                    </div>
                                  </div> */}
                                  {/* <div className="w-full flex flex-col sm:flex-row md:flex-col lg:flex-row justify-between border-t border-slate-300 px-3 py-2">
                                    <div className="text-semibold">
                                      Foreign Transaction Fee:
                                    </div>
                                    <div>
                                      Under 35USD: 0.5USD; Over 35USD: 1.5%
                                    </div>
                                  </div> */}
                                  <div className="border-t border-slate-300 px-3 py-2">
                                    <span className="text-red-500">*</span>{" "}
                                    Please pay attention, if your transactions
                                    have been declined 5x in a single payment, the transaction fee will
                                    still be charged and card will be terminated!
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="w-full flex flex-col md:flex-row justify-center gap-12 items-start py-20">
                          <div className="w-full md:max-w-[400px] ">
                            <div className="w-full  flex justify-start">
                              <CardInfo />
                            </div>
                          </div>
                          <div className="justify-start">
                            <div className=" max-w-[600px]  shadow border bg-white border-slate-100 px-5 py-5 flex flex-col items-center rounded-md">
                              <div className="text-slate-700 w-full font-bold lg:text-3xl text-2xl text-center flex flex-col gap-2">
                                {user.pending_order === 0
                                  ? "Order new card"
                                  : "Order under review"}
                                <hr />
                              </div>

                              <div className="py-4">
                                <img
                                  src='/credit-card.png'
                                  alt=""
                                  className="h-48 w-auto"
                                />
                              </div>
                              <div className="text-slate-600 text-center py-4">
                                {user.pending_order === 0
                                  ? "Currently, you don't have an active card. Seize the opportunity to create a new card and experience aworld of convenience and possibilities!"
                                  : "Your order is currently under review. We will inform you as soon as the process is complete. Thank you for choosing us!"}
                              </div>
                              {user.pending_order === 0 && (
                                <MainButton
                                  label={"New Card"}
                                  onClick={createCard}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : user.is_kyc_completed === 1 &&
                  user.is_kyc_approved === 0 ? (
                  <>
                    <div className="w-full min-h-[60vh] flex justify-center items-center md:p-20 bg-slate-100">
                      <div className="w-[90%] sm:max-w-[600px] bg-white min-h-68 p-2 md:p-9 rounded-md border-slate-100 shadow">
                        <div className="w-full text-2xl font-bold text-slate-700 py-5 text-center">
                          KYC verification under review
                          <hr />
                        </div>
                        <div className="w-full flex justify-center">
                          <img
                            src={kycCheckPrivacy}
                            alt="privacy"
                            className="h-72 w-72"
                          />
                        </div>
                        <div className="text-slate-600 p-2 flex text-center w-full">
                          Your information is under review. Please wait, and
                          we'll notify you once the process is complete.
                        </div>
                      </div>
                    </div>
                  </>
                ) : user.is_kyc_completed === 0 &&
                  user.is_kyc_approved === 0 ? (
                  <>
                    <div className="w-full  flex flex-col md:flex-row justify-center gap-12 items-start py-2">
                      <div className="flex-none ">
                        <div className="w-full flex justify-start ">
                          <CardInfo />
                        </div>
                      </div>
                      <div className=" w-full flex justify-center ">
                        <div className=" max-w-[600px]  shadow border bg-white border-slate-100 px-5 py-5 flex flex-col items-center rounded-md">
                          <div className="text-slate-700 w-full font-bold lg:text-3xl text-2xl text-center flex flex-col gap-2">
                            {user.pending_order === 0
                              ? "Order new card"
                              : "Order under review"}
                            <hr />
                          </div>

                          <div className="py-4">
                            <img
                              src={creditCardSvg}
                              alt=""
                              className="h-48 w-auto"
                            />
                          </div>
                          <div className="text-slate-600 text-center py-4">
                            {user.pending_order === 0
                              ? "Currently, you don't have an active card. Seize the opportunity to create a new card and experience aworld of convenience and possibilities!"
                              : "Your order is currently under review. We will inform you as soon as the process is complete. Thank you for choosing us!"}
                          </div>
                          {user.pending_order === 0 && (
                            <MainButton
                              label={"New Card"}
                              onClick={verifyUser}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {token ? (
                      <div>
                        <div>
                          <VirtualCardShimmer
                            rounded={"rounded-lg"}
                            className={`bg-slate-200 flex items-center justify-center w-[170px] h-[50px] rounded-lg my-2`}
                          />
                        </div>
                        <div className="flex flex-col sm:flex-row w-full justify-around gap-5 py-10">
                          <div>
                            {/* <VirtualCardShimmer
                              rounded={"rounded-lg"}
                              className={`bg-slate-200 flex items-center justify-center w-[170px] h-[50px] rounded-lg my-2`}
                            /> */}

                            <VirtualCardShimmer
                              rounded={"rounded-lg"}
                              className={`bg-slate-200 flex items-center justify-center w-full md:w-[400px]  h-[200px] sm:h-[300px] md:h-[200px] rounded-lg`}
                            />
                            <div className="py-4 flex justify-around">
                              <VirtualCardShimmer
                                rounded={"rounded-full"}
                                className={`bg-slate-200 flex items-center justify-center  w-[60px] h-[60px] rounded-full`}
                              />
                              {/* <VirtualCardShimmer
                                rounded={"rounded-full"}
                                className={`bg-slate-200 flex items-center justify-center  w-[60px] h-[60px] rounded-full`}
                              /> */}
                              <VirtualCardShimmer
                                rounded={"rounded-full"}
                                className={`bg-slate-200 flex items-center justify-center  w-[60px] h-[60px] rounded-full`}
                              />
                            </div>
                          </div>
                          <div className="w-full flex flex-col gap-5">
                            <div className="flex flex-col gap-2">
                              <VirtualCardShimmer
                                rounded={"rounded-lg"}
                                className={
                                  "w-[140px] bg-slate-200 h-[20px] rounded-lg"
                                }
                              />
                              <VirtualCardShimmer
                                rounded={"rounded-lg"}
                                className={
                                  "w-[150px] hidden lg:block bg-slate-200 h-[50px] rounded-lg"
                                }
                              />
                            </div>
                            <div className="flex flex-col gap-3 w-full bg-white h-[400px] rounded-lg p-2">
                              <VirtualCardShimmer
                                rounded={"rounded-lg"}
                                className={
                                  "w-full bg-slate-200 h-[75px] rounded-lg"
                                }
                              />
                              <VirtualCardShimmer
                                rounded={"rounded-lg"}
                                className={
                                  "w-full bg-slate-200 h-[75px] rounded-lg"
                                }
                              />
                              <VirtualCardShimmer
                                rounded={"rounded-lg"}
                                className={
                                  "w-full bg-slate-200 h-[75px] rounded-lg"
                                }
                              />
                              <VirtualCardShimmer
                                rounded={"rounded-lg"}
                                className={
                                  "w-full bg-slate-200 h-[75px] rounded-lg"
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      navigate("/login")
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        isLoading === false && (
          <div className="w-full h-full md:h-screen">
            <VirtualCardLanding />
          </div>
        )
      )}
    </>
  );
};

export default VirtualCards;







