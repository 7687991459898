import { useState, useEffect } from "react";

const Modal = ({ isOpen, onClose, children }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [startY, setStartY] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const modalHeight = window.innerHeight; // Full viewport height
  const bottomThreshold = modalHeight * 0.6; // Close when dragged to the bottom 10% (can change to 0.15 for 15%)

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden"); // Lock scrolling
      setIsAnimating(true); // Start the animation for modal opening
      setCurrentY(0); // Ensure it starts from the bottom when opened
    } else {
      document.body.classList.remove("overflow-hidden"); // Unlock scrolling
      setIsAnimating(false); // End the animation when closing
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("overlay")) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsAnimating(false);
    setTimeout(onClose, 300); // Ensure animation completes before unmount
  };

  const handleTouchStart = (event) => {
    setStartY(event.touches[0].clientY);
    setIsDragging(true);
  };

  const handleTouchMove = (event) => {
    if (isDragging) {
      const touchY = event.touches[0].clientY;
      setCurrentY(Math.max(0, touchY - startY)); // Only allow positive drag offset
    }
  };

  const handleTouchEnd = () => {
    if (currentY > bottomThreshold) {
      // Close modal if dragged to the bottom 10% or 15%
      handleClose();
    } else {
      // Reset modal position back to top if it doesn't reach the threshold
      setCurrentY(0);
    }
    setIsDragging(false);
  };

  if (!isOpen && !isAnimating) {
    return null;
  }

  return (
    <div
      onClick={handleOverlayClick}
      className="fixed inset-0 flex items-end justify-center z-50 overlay overflow-hidden sm:hidden" // Show only on mobile
    >
      {/* Overlay */}
      <div className="bg-black opacity-70 fixed inset-0 overlay cursor-pointer"></div>

      {/* Modal Content */}
      <div
        className={`relative bg-white w-full fixed bottom-0 left-0 p-4 md:p-6 rounded-t-3xl shadow-lg z-10 transform transition-transform duration-300 ease-out ${
          isOpen ? "translate-y-0" : "translate-y-full"
        }`}
        style={{
          transform: `translateY(${currentY}px)`, // Update modal position based on drag
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {/* Dynamic Island */}
        <div className="w-full flex justify-center items-center mb-4">
          <div className="w-16 h-2 bg-gray-300 rounded-full cursor-pointer"></div>
        </div>

        {/* Modal Content */}
        <div className="w-full h-full">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
